import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { LANG } from "../lang"

import "./index.scss"
import { LinkButton } from "../components/button/button"
import { WorkList } from "../components/work-list";
// import Image from "../components/Image";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`ceng`, `engineering`, `mobile`, 'development', 'software', 'consulting', 'hamilton']} />
    <div className="heading-container">
      <div className="heading-title">
        <h1>{LANG.home_page_heading}</h1>
        <p>{LANG.home_page_heading_content}</p>
      </div>
      <img src={require('../images/Homepage-Asset_01.png')} className="image" alt="" />
    </div>

    <div className="home-section-2">
      <div className="content">
        <h2>We Look Good Together.</h2>
        <p>
          Whether you are looking for development acumen that complements your
          existing team, or an Engineering as a Service platform,{" "}
          <b>you’ve come to the right place</b>. At CENG Technologies, we
          provide the business and technology expertise required to complete
          specific deliverables, or satisfy the demand for a self-sustaining,
          elastically scalable engineering team.
        </p>
      </div>
      <img src={require('../images/Homepage-Asset_02.png')} className="image" alt="" />
    </div>

    <div className="home-section-3">
      <div className="content">
        <p>
          Working with CENG means working with an efficient service provider
          that encompasses all aspects of Talent Acquisition, Infrastructure,
          Business Operations, Technology Development, Human Resources, and
          Education. Our team embraces multi-disciplined engineers, each with
          unique skills and work experience, so you’ll have more time to grow
          your business, and{" "}
          <b>we’ll be there to support and sustain your growth</b>.
        </p>
        <p>
          To consistently deliver the highest quality products possible, we’ve
          designed and implemented unique work flows and business practices
          intended for transparency and collaboration.
        </p>
        <p>We’re pretty proud of it.</p>
        <LinkButton href="/downloads/cengtech-info_document.pdf" target="_blank">Take A Look</LinkButton>
      </div>
      <img src={require('../images/Homepage-Asset_03.png')} className="image" alt="" />
    </div>

    <div className="services" id="services">
      <div className="service_item">
        <div />
        <div>
          <h2>Engineering <i>à la Carte</i></h2>
        </div>
      </div>

      <div className="service_item">
        <div>
          <img src={require("../images/software.png")} alt=""/>
        </div>
        <div>
          <h3>Software</h3>
          <p>
            The programs and interfaces that make the world go ‘round start with
            an intricate design process and extensive development methodologies.
            At CENG, we like to think we’ve got these down to a science. Mobile
            applications, server applications, desktop applications –{" "}
            <b>look no further</b>.
          </p>
        </div>
      </div>

      <div className="service_item">
        <div>
          <img src={require("../images/hardware.png")} alt="" />
        </div>
        <div>
          <h3>Hardware</h3>
          <p>
            <b>Our first love</b>. CENG was built upon a foundation of hardware
            development and prototyping, we’ve since expanded to include
            complimentary services and products, but we’ll always have a special
            place in our heart for the design and development of PCBs, Embedded
            Systems, and Testbenches.
          </p>
        </div>
      </div>

      <div className="service_item">
        <div>
          <img src={require("../images/iot.png")} alt="" />
        </div>
        <div>
          <h3>IoT</h3>
          <p>
            We service companies operating in the age of{" "}
            <b>global connectivity</b>, understanding and building projects that
            take advantage of the Internet of Things is a necessity. Some of the
            key components we provide include SCADA System Design, Legacy System
            Integration, and Onsite Support and Maintenance.
          </p>
        </div>
      </div>

      <div className="service_item">
        <div>
          <img src={require("../images/control_automation.png")} alt="" />
        </div>
        <div>
          <h3>Control & Automation</h3>
          <p>
            This is where CENG excels at creating efficient, resourceful, and
            sustainable business processes and practices. We’re talking Remote
            Sensor Integration, LoRaWAN Technology Capabilities, Data Collection
            and Analysis… you know, the kind of technology that works for you.
          </p>      
        </div>
      </div>
      <img src={require('../images/Homepage-Asset_04.png')} className="image" alt="" />
    </div>
    <div className="work">
        <div className="title">
          <h2>Our Work</h2>
          <p>(Click to Explore)</p>
        </div>
        <WorkList />
        <img src={require('../images/Homepage-Asset_05.png')} className="image" alt="" />
      </div>
  </Layout>
)

export default IndexPage
